*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#root{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

body{
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    background-color:#315563;
    font-family: 'Lato', sans-serif;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

.active{
    width: 100%;
    height: 3px;
    border-bottom: 3px solid #C7EEFC;
}