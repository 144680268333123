.ListTodo{
    background-color: #B2C9C3;
    width: 70vw;
    padding-bottom: 1em;
}

.ListTodo__title{
    display: flex;
    justify-content: center;
    padding: 0.5em;
    color: white;
    font-size: 4rem;
    font-weight: 100;
}