.Header{
    padding: 1em;
    margin-top: 1em;
    width: 70vw;
    text-align: center;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}
.Header__title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    font-weight: 800;
    color: white;
}