.OneTaskDelete{
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #B2C9C3;
    width: 70vw;
    padding-bottom: 1em;
}
.OneTaskDelete__title{
    font-weight: 100;
    margin-bottom: 0.5em;
    text-align: center;
    color: white;
}

.OneTaskDelete__confirm{
    display: flex;
    justify-content: center;
    background-color: #B2C9C3;
    width: 70vw;
    height: 10vh;
    font-weight: 100;
}

.OneTaskDelete__confirm-info{
    padding: 1em 0;
    color: white;
}
.OneTaskDelete__confirm-info--detail{
    display: flex;
    justify-content: center;
    padding: 1em 0.2em;
    color: #D08C85;
}
.OneTaskDelete__confirm-info-link{
    display: block;
    color: white;
    text-decoration: none;
}

.OneTaskDelete__button{
    background-color: #D08C85;
    color: white;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    min-width: 7em;
    height: 3em;
    border: none;
    padding: 0 0.5em;
}

