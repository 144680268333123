.Navigation{
    width: 70vw;
    padding: 1em;
    margin-bottom: 1em;
    border-bottom: 2px solid #C7EEFC;
    background-color: #B2C9C3;
    display: flex;
    justify-content: space-evenly;
}

.Navigation__item {
    list-style: none;
    letter-spacing: 2px;
    font-size: 2.8rem;
    text-transform: uppercase;
}

.Navigation__item a{
    text-decoration: none;
    color: white;
    list-style: none;
}

.Navigation__option{
    background-color: purple;
}

.Navigation__button{
    font-size: 2.8rem;
    font-weight: 900;
    color: white;
    background-color: transparent;
    border: 2px solid red;
    padding: 0 1em;
    margin: 0 0.5em;
    cursor: pointer;

}