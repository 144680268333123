.FormEdit{
    display: flex;
    justify-content: center;
    background-color: #B2C9C3;
    width: 70vw;
    height: 10vw;
    padding-bottom: 2em;
}
.FormEdit__back{
    display: flex;
    justify-content: center;
    background-color: #B2C9C3;
    width: 70vw;
    padding-bottom: 1em;
}

.FormEdit input{
    padding: 2em;
    margin: 1em;
    width: 100%;
    background-color: #B2C9C3;
    border:2px solid #C7EEFC ;
    color: white;
}

.FormEdit__button{
    padding: 0 2em;
    margin: 1em 1em;
    background-color: #D08C85;
    color: white;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
}

label{
    padding: 2em;
    margin: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}


.FormEdit__info{
    display: flex;
    justify-content: center;
    width: 70vw;
    height: 10vh;
    background-color: #B2C9C3;
    padding: 1em;
    color: white;
    font-weight: 100;
}
.FormEdit__info--detail{
    font-weight: 100;
    padding: 0 0.5em;
    color: #D08C85;
}

.FormEdit__info--title{
    font-weight: 100;
}


